@import "../variables";

.quiz_wrapper {
  background: $verizon-black;
  background-image: url('../img/left_part.png'), url('../img/right_part.png');
  background-position: left top, right top;
  background-repeat: no-repeat;
  background-size: contain;
  color: $verizon-white;
  text-align: center;
  display: flex;
  height: 700px;
  max-height: 700px;
  max-width: 1280px;
  margin: 0 auto;
  flex: 0 1 auto;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px 2.5em;
  font-family: 'Haas Grotesk', Helvetica, Arial, sans-serif;
  font-size: 14px/16px;
  box-sizing: border-box;

  .quiz_upper {
    min-height: 465px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    font-size: 40px;
    margin: 50px auto;
    width: 75%;
  }

  .quiz_characters_holder {
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .quiz_character {
    box-sizing: border-box;
    background: url('../img/no_answer.png') top center no-repeat black;
    background-size: contain;
    border-radius: 50%;
    max-width: 135px;
    max-height: 135px;
    margin: 1vw;
    width: 13vw;
    height: 13vw;
  }

  .quiz_answer_holder .quiz_character {
    margin: 20px auto 20px;
    width: 200px;
    height: 200px;
    max-width: 40vw;
    max-height: 40vw;
  }

  .quiz_answer_text {
    font-size: 20px;
    width: 75%;
    max-width: 700px;
    margin: 25px auto 35px;
    line-height: 1.3;
  }

  .quiz_share_title {
    margin-top: 100px;
    margin-bottom: 20px;
    font-size: 40px;
  }

  .quiz_share_text {
    max-width: 776px;
    font-size: 24px;
    text-align: justify;
  }

  .quiz_character_holder {
    min-height: 220px;
  }

  .quiz_character_title {
    font-size: 18px;
    margin: 0.7em auto 0;
    font-weight: bold;
    max-width: 13vw;
  }

  .quiz_share_buttons_holder {
    display: flex;
    justify-content: center;
  }

  .quiz_share_button {
    margin: 0 2em;
    font-size: 20px;
    text-align: center;
    cursor: pointer;

    img {
      margin: 0 auto 10px auto;
      width: 50%;
      display: block;
    }
  }
}

.quiz_hidden {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .quiz_wrapper {
    background-image: url('../img/border_1.png'), url('../img/border_2.png');
    background-position: 15px 15px, calc(100% - 15px) calc(100% - 15px);
    background-repeat: no-repeat;
    background-size: 150px;
    padding: 0 50px 2.5em;

    .quiz_share_title {
      margin-top: 30px;
    }

    .quiz_share_text {
      width: 85%;
      font-size: 20px;
    }
  }
}

@media only screen and (max-width: 970px) {
  .quiz_wrapper {
    padding: 20px 20px 2.5em;
    height: 100%;
    max-height: 100%;


    .quiz_upper {
      min-height: 500px;
    }

    h2, .quiz_share_title {
      font-size: 40px;
      margin-top: 30px;
    }

    .quiz_characters_holder {
      padding: 20px 70px;
    }

    .quiz_character_holder {
      min-height: 220px;
    }

    .quiz_character {
      width: 40vw;
      height: 40vw;
    }

    .quiz_character_title {
      font-size: 16px;
      max-width: 135px;
    }

    .quiz_share_button {
      margin: 0 0.3em;
    }
  }
}

@media only screen and (max-width: 650px) {
  .quiz_wrapper {

    h2, .quiz_share_title {
      margin-top: 60px;
    }

    .quiz_share_text, .quiz_share_button, .quiz_answer_text {
      font-size: 16px;
    }

    .quiz_characters_holder {
      padding: 20px 0;
    }

    .quiz_character_holder {
      min-height: 180px;
    }

    .quiz_character {
      width: 20vw;
      height: 20vw;
      margin: 0 2vw;
    }

    .quiz_character_title {
      font-size: 14px;
      max-width: 20vw;
    }
  }
}

@media only screen and (max-width: 540px) {
  .quiz_wrapper {

    h2, .quiz_share_title {
      font-size: 30px !important;
      margin-top: 80px;
    }

    .quiz_character_holder {
      min-height: 120px;
    }

    .quiz_character {
      width: 30vw;
      height: 30vw;
      margin: 0 1vw;
    }

    .quiz_character_title {
      display: none !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  .quiz_wrapper {

    .quiz_character_holder {
      min-height: 106px;
    }

    .quiz_character {
      width: 25vw;
      height: 25vw;
      margin: 0 1.5vw;
    }

    .quiz_character_title {
      display: none !important;
    }
  }
}