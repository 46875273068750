.quiz_row {
  color: $verizon-black;
  width: 100%;
  font-family: 'Haas Grotesk', Helvetica, Arial, sans-serif;
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  max-width: 1280px;
  margin: 0 auto;

  .quiz_column {
    width: 50%;
    position: relative;

    img {
      width: 100%;
      line-height: 0;
    }
  }

  .quiz_mosaic_image_holder {
    line-height: 0;
  }

  .quiz_mosaic_header {
    font-size: 40px;
    margin: 20px 30px 5px 30px;

    sup {
      font-weight: normal;
    }
  }

  .quiz_mosaic_subheader {
    margin: 0 30px;
    font-size: 0.8em;
  }

  .quiz_mosaic_description {
    font-size: 18px;
    margin: 15% 80px 0 30px;
    padding-top: 10px;
    border-top: 1px solid $verizon-black;
    line-height: 1.4;
  }

  .quiz_mosaic_footer {
    margin: 0;
    padding: 40px;
    border-top: 1px solid $verizon-black;
    line-height: 1.4;
    font-size: 1.2em;
  }

  .quiz_mobile_only {
    display: none;
  }

  .quiz_bring_bottom {
    position: absolute;
    bottom: 15px;
    left: 30px;
  }

  #quiz_video_button {
    cursor: pointer;
  }

  a {
    color: $verizon-black;
  }

  .right-arrow {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-right: 2px solid #000;
    border-bottom: 2px solid #000;
    transform: rotate(-45deg);
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

.quiz_clearfix {
  clear: both;
}

@media only screen and (max-width: 1175px) {
  .quiz_row {
    .quiz_mosaic_header {
      font-size: 1.5em;
    }
    .quiz_mosaic_description {
      margin-top: 5%;
      font-size: 1em;
    }
    .quiz_mosaic_footer {
      font-size: 1em;
    }
  }
}

@media only screen and (max-width: 860px) {
  .quiz_row {
    .quiz_column {
      width: 100%;
    }
    .quiz_mosaic_description {
      margin-bottom: 50px;
    }
    .quiz_mosaic_footer {
      padding: 30px;
    }
    .quiz_mobile_only {
      display: block;
    }

    .quiz_desktop_only {
      display: none;
    }
  }
}