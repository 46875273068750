.quiz_modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.quiz_modal {
  background: $verizon-white;
  color: $verizon-black;
  position: absolute;
  width: 80vw;
  height: 100%;
  max-height: 80vh;
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  font-family: 'Haas Grotesk', Helvetica, Arial, sans-serif;
  padding: 0 20%;
  box-sizing: border-box;
  .close {
    position: absolute;
    right: 0;
    top: -35px;
    width: 32px;
    height: 32px;
    opacity: .7;
    cursor: pointer;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #ffffff;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}

.quiz_modal_pending {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.8);
  display: none;

  .quiz_modal_pending_ring {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .quiz_modal_pending_ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px solid rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.5) transparent rgba(0, 0, 0, 0.5) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


}

@media screen and (min-width: 0 \0
) {
  .quiz_modal {
    top: 10vh;
    left: 10vw;
  }
}

.quiz_share_buttons_wrapper {
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  font-size: 1.5em;
  flex-wrap: wrap;
  overflow-x: auto;
  align-content: center;

  #quiz_share_to_facebook, #quiz_share_to_twitter {
    margin: 5vw 20px;
    cursor: pointer;

    img {
      height: auto;
      margin: 0 auto 0.5em;
      display: block;
    }

    .quiz_twitter_form {
      cursor: auto;
      margin: 20px 0;
      max-width: 40vw;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }

    input {
      border: none;
      border-bottom: 1px solid #000000;
      margin: 0 0 0.9em;
      padding: 10px 5px;
      font-family: 'Haas Grotesk', Helvetica, Arial, sans-serif;
      font-size: 18px;
      text-align: center;
      border-radius: 0;
    }

    .quiz_hidden {
      display: none;
    }
  }
}

.quiz_video_modal {
  padding: 0;
  height: auto;
}

.quiz_hidden {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .quiz_modal {
    padding: 0 8%;
  }
  .quiz_video_modal {
    padding: 0;
  }
}

@media only screen and (max-width: 640px) {
  .quiz_modal {
    padding: 0 5%;
    width: 85vw;
    max-height: 85vh;
    top: 7.5vh;
    left: 7.5vw;

    .quiz_share_buttons_wrapper {
      #quiz_share_to_twitter {

        .quiz_twitter_form {
          max-width: 100%;
        }
      }
    }
  }
  .quiz_video_modal {
    padding: 0;
  }
}

@media only screen and (max-height: 550px) {
  .quiz_modal {
    padding: 0 5%;
    width: 85vw;
    max-height: 85vh;
    top: 7.5vh;
    left: 7.5vw;

    .quiz_share_buttons_wrapper {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      #quiz_share_to_facebook, #quiz_share_to_twitter {
        max-width: 25vw;

        img {
          height: 24vh;
          width: 25vh;
        }

        .quiz_twitter_form {
          margin-top: 5px;
          margin-bottom: 5px;
        }
      }
    }
  }
}