.quiz_button {
  border: 2px solid $verizon-white;
  background: $verizon-black;
  color: $verizon-white;
  font-size: 1rem;
  font-family: "Haas Grotesk", Arial, sans-serif;
  padding: 1em 3.14em;
  border-radius: 40px;
  font-weight: bold;
  margin: 0 20px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.quiz_button--white {
  background: $verizon-white;
  color: $verizon-black;
}

@media only screen and (max-width: 650px) {
  .quiz_button_holder {
    display: flex;
    flex-direction: column;
  }
  .quiz_button {
    margin: 20px auto;
  }
}