@font-face {
  font-family: 'Haas Grotesk';
  src: url('../fonts/Haas Grotesk Bold.eot');
  src: url('../fonts/Haas Grotesk Bold.woff2') format('woff2'),
  url('../fonts/Haas Grotesk Bold.woff') format('woff'),
  url('../fonts/Haas Grotesk Bold.ttf') format('truetype'),
  url('../fonts/Haas Grotesk Bold.svg#NHaasGroteskDSStd-75Bd') format('svg'),
  url('../fonts/Haas Grotesk Bold.eot?#iefix') format('embedded-opentype');
  font-weight: bold;
  font-style: normal;

}

@font-face {
  font-family: 'Haas Grotesk';
  src: url('../fonts/Haas Grotesk.eot');
  src: url('../fonts/Haas Grotesk.woff2') format('woff2'),
  url('../fonts/Haas Grotesk.woff') format('woff'),
  url('../fonts/Haas Grotesk.ttf') format('truetype'),
  url('../fonts/Haas Grotesk.svg#NHaasGroteskDSStd-55Rg') format('svg'),
  url('../fonts/Haas Grotesk.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

h1, h2, h3, h4 {
  font-family: 'Haas Grotesk', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
